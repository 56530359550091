<template>
  <v-row>
    <v-col cols="7" sm="6" md="7">
      <div class="div_header" style="background-color: red">
        Primary Barrier
      </div>
      <listitems
        :title="'Barriers'"
        :list="data_1"
        :toolbar="false"
        :headers="headers"
        :key="kid1"
        :ipg="20"
        :hdf="true"
      />
      <div class="div_header" style="background-color: orange">
        Seconadry Barrier
      </div>
      <listitems
        :title="'Barriers'"
        :list="data_2"
        :toolbar="false"
        :headers="headers"
        :key="kid2"
        :ipg="20"
        :hdf="true"
      />
      <div class="div_header" style="background-color: blue">
        Tertiary Barrier
      </div>
      <listitems
        :title="'Barriers'"
        :list="data_3"
        :toolbar="false"
        :headers="headers"
        :key="kid3"
        :ipg="20"
        :hdf="true"
      />
      <div class="div_header" style="background-color: green">
        Forth Barrier
      </div>
      <listitems
        :title="'Barriers'"
        :list="data_4"
        :toolbar="false"
        :headers="headers"
        :key="kid4"
        :ipg="20"
        :hdf="true"
      />
    </v-col>
    <v-col cols="5" sm="5" md="5">
      <component_svg
        :well="well_svg"
        :dialogform="false"
        :key="ksvg2"
        :barrier_1="true"
        log="main2"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    component_svg: () => import("../components/ComponentSVG.vue"),
  },
  props: {
    well: Object,
    data: Array,
  },
  data() {
    return {
      well_svg: {},
      ksvg2: 100,
      data_1: [],
      data_2: [],
      data_3: [],
      data_4: [],
      kid1: 40000,
      kid2: 10000,
      kid3: 20000,
      kid4: 30000,
      headers: [
        { text: "Code", value: "code", selected: true },
        { text: "Item", value: "item", selected: true },
        { text: "Qualifications", value: "test", selected: true },
        {
          text: "Status",
          value: "severity",
          selected: true,
          slot: "chip",
          color: "color",
        },
      ],
    };
  },
  watch: {},
  async mounted() {
    if (this.data.length > 0) {
      this.data_1 = this.data.filter((x) => x.order == 1);
      this.data_2 = this.data.filter((x) => x.order == 2);
      this.data_3 = this.data.filter((x) => x.order == 3);
      this.data_4 = this.data.filter((x) => x.order == 4);
    }
    this.kid1++;
    this.kid2++;
    this.kid3++;
    this.kid4++;
    this.get_svg();
  },
  computed: {},
  methods: {
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },
    get_svg() {
      this.ksvg2++;
      this.well_svg = {};
      let lst = this.well.wellscomponents;

      //treecap
      if (lst.length > 0) {
        let treecap = lst.findIndex((x) => x.code == "4");
        if (treecap > 0)
          this.well_svg.gauge = {
            id: lst[treecap].id,
            code: "4",
            label: "TreeCap",
          };

        //addxtree_top

        let xtree_top = lst
          .sort((a, b) => b.code - a.code)
          .filter(
            (x) => x.group_id == 1 && x.orientation == "T" && x.code != "4"
          );

        if (lst.length > 0 || xtree_top.legth > 0)
          this.well_svg.Manifold = { id: 2000, code: "200", label: "Manifold" };
        let xtree_left = lst
          .sort((a, b) => b.code - a.code)
          .filter((x) => x.group_id == 1 && x.orientation == "L");
        let xtree_right = lst
          .sort((a, b) => b.code - a.code)
          .filter((x) => x.group_id == 1 && x.orientation == "R");
        let xtree = lst
          .sort((a, b) => b.code - a.code)
          .filter((x) => x.group_id == 1 && x.orientation == null);

        if (xtree_top.length > 0) this.well_svg.xtree_top = xtree_top;
        if (xtree_left.length > 0)
          this.well_svg.xtree_left = { components: xtree_left };
        if (xtree_right.length > 0)
          this.well_svg.xtree_right = { components: xtree_right };

        if (xtree.length > 0) this.well_svg.xtree = xtree;
        let k = this.well.wellstubulars.findIndex((x) => x.tubular_id == 1);
        if (k >= 0) {
          this.well_svg.tubulars = {
            depth: this.well.wellstubulars[k].depth,
            components: lst.filter((x) => x.group_id == 13),
          };
        }
        this.well_svg.Annulus = [];

        let annulus = lst.filter((x) => x.group_id == 2);

        let casings = this.well.wellstubulars
          .filter((x) => x.tubular_id != 1 && x.depthfrom == 0)
          .sort((a, b) => a.csg_order - b.csg_order);

        //

        let csg_order = 0;
        if (casings.length > 0) {
          casings.forEach((x) => {
            this.well_svg.Annulus.push({
              csg_order: csg_order + 1,
              depth: x.depth,
              components: annulus.filter((i) => i.csg_order == csg_order + 1),
            });
            csg_order++;
          });
        }
        //well head group si required

        //CSG
        let csgs = lst.filter((x) => x.group_id == 9);
        if (csgs.length > 0) this.well_svg.csgs = csgs;
        //tubing 30
        let t30 = lst.findIndex((x) => x.code == "30");
        if (t30 >= 0)
          this.well_svg.TUBING30 = {
            id: lst[t30].id,
            code: "30",
            label: "Tubing",
          };
        //tubing 31
        let t31 = lst.findIndex((x) => x.code == "31");
        if (t31 >= 0)
          this.well_svg.TUBING31 = {
            id: lst[t31].id,
            code: "31",
            label: "Tubing",
          };
        //DHSV
        let t32 = lst.findIndex((x) => x.code == "52");
        if (t32 >= 0)
          this.well_svg.TUBING32 = {
            id: lst[t32].id,
            code: "52",
            label: "DHSV",
          };
        // //corrosion
        // this.well_svg.corrosion_barriers = this.corrosions_list;
        // //cements
        // this.well_svg.cement_barriers = this.cements_list;
        // //formations
        // this.well_svg.formations = this.formations_list.filter(
        //   (elm) => elm.active == 1
        // );
        this.ksvg2++;
      }
    },
  },
};
</script>
<style scoped>
.div_header {
  text-align: center;
  height: 35px;
  margin-top: 4px;
  font-size: 16px;
  padding: 4px;
  color: white;
}
</style>
